import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React from "react";
import { QRCode } from "react-qr-svg";
import { differenceInSeconds } from "date-fns";
import {
  Lock,
  Close,
  AccessTime,
  IosShare,
  PhotoCamera,
  ArrowForward,
  SupportAgent,
} from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components/macro";
import {
  child,
  endAt,
  endBefore,
  get,
  limitToFirst,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
  set,
  startAfter,
  startAt,
  update,
} from "firebase/database";

import {
  database,
  reference_or_query_to_string,
  useFirebase,
} from "./utils/firebase.js";
import {
  useConnected,
  useDate,
  useDialogRef,
  useTimeout,
} from "./utils/use.js";
import { useTranslation } from "./languages.js";
import { sortBy, startCase } from "lodash";
import { Dialog } from "./utils/Dialog.js";
import { QrCameraView } from "./components/QrCameraView.js";
import { PhoneNumberView } from "./components/phone-number-view.js";
import {
  GoodPhoneCallScreen,
  WrongPhoneCallScreen,
} from "./components/phone-calling-screen.js";
import { Whitespace } from "./Elements.js";
import {
  IsDevelopmentContext,
  useIsDevelopment,
} from "./utils/use-is-development.js";
import { DevButton } from "./components/DevButton.js";

let LanguageButton = styled.button`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  /* aspect-ratio: 2/3; */

  padding: 0;
  padding-top: calc(2 / 3 * 100%);

  &.selected {
    box-shadow: inset black 0px 0px 0px 5px, inset white 0px 0px 0px 10px;
  }
`;

function LanguagesView() {
  let [language, set_language] = React.useState("nl");
  let { kist_naam } = useParams();
  let navigate = useNavigate();
  let t = useTranslation(language);

  return (
    <div style={{ 
      padding: 16, 
      textAlign: "center",
    }}
      >
      <h1>KNVB Game</h1>

      <div
        style={{
          display: "flex",
          width: "50%",
          alignSelf: "center",
        }}
      >
        <LanguageButton
          onClick={() => set_language("nl")}
          className={language === "nl" ? "selected" : ""}
          title="Nederlands"
          style={{
            gridArea: "NL",
            alignSelf: "center",
            backgroundImage: `url("http://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg")`,
          }}
        
        ></LanguageButton>
      </div>
      <Whitespace height={32} />
      <button
        onClick={() => {
          navigate(`/${kist_naam}/${language}/game`);
        }}
      >
        {t("In welke taal wil je spelen?")}
      </button>
    </div>
  );
}

let HOW_LONG_DO_WE_HAVE_IN_SECONDS = 60 * 120;

let TimeThingy = ({ room }) => {
  let date = useDate();
  let t = useTranslation();
  let finished_time = room?.finished_time;
  let seconds_elapsed = differenceInSeconds(
    finished_time ?? date,
    room?.start_time
  );

  let time_from_90_minutes = HOW_LONG_DO_WE_HAVE_IN_SECONDS - seconds_elapsed;

  let dialog = useDialogRef();

  if (room.start_time == null) {
    return null;
  }

  if (time_from_90_minutes < 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: "red",
        }}
      >
        Time is up!
      </div>
    );
  }

  return (
    <>
      <Dialog
        className="fancy-backdrop"
        ref={dialog}
        onClick={(e) => {
          dialog.current.close();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: 16,

            margin: "auto",

            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>Timing</h1>
            <Close
              onClick={() => {
                dialog.current.close();
              }}
            />
          </div>
          <div
            style={{
              minWidth: "100%",
              backgroundColor: `rgba(0, 0, 0, 0.2)`,
              borderRadius: 8,
              padding: 16,

              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              marginBottom: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "1.2em",
                fontVariant: "tabular-nums",
              }}
            >
              <AccessTime />
              <div style={{ minWidth: 8 }} />
              {(time_from_90_minutes / 60 - 1).toFixed(0)}:
              {(time_from_90_minutes % 60).toFixed(0).padStart(2, "0")}
            </div>
            {t(
              "You have 90 minutes to finish the game. You'll see the time you have left in the top of the screen."
            )}
          </div>

          <div
            style={{
              minWidth: "100%",
              backgroundColor: `rgba(0, 0, 0, 0.2)`,
              borderRadius: 8,
              padding: 16,

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ color: "#af0000", fontSize: "1.2em" }}>- 5 min</div>
            {t(
              "For every hint you unlock, there will be some time added to your final score. You will still be able to play the full 90 minutes, but your final score will be a bit less."
            )}
          </div>
        </div>
      </Dialog>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: finished_time != null ? "green" : undefined,
          fontVariant: "tabular-nums",
        }}
        onClick={() => {
          dialog.current.showModal();
        }}
      >
        <AccessTime />
        <div style={{ minWidth: 8 }} />
        {(time_from_90_minutes / 60 - 1).toFixed(0)}:
        {(time_from_90_minutes % 60).toFixed(0).padStart(2, "0")}
        <div style={{ minWidth: 8 }} />
        {room?.hints_penalty && (
          <span style={{ color: "red" }}>
            {"- "}
            {room?.hints_penalty} min
          </span>
        )}
      </div>
    </>
  );
};

/**
 * @type {React.Context<import("firebase/database").DatabaseReference>}
 */
let RoomContext = React.createContext(null);

let IsOptimusMasterControllerPrimeContext = React.createContext(false);

let DEVELOPMENT_PARK_NAME = "dev-park";

let Stack = ({ wrappers, children }) => {
  return wrappers.reduceRight(
    (acc, x) => React.cloneElement(x, { children: acc }),
    children
  );
};

let SubtleButton = styled.button`
  all: unset;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: background-color 0.1s;
  cursor: pointer;

  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

let Topbar = ({ children = null }) => {
  return (
    <div
      style={{
        top: 0,
        position: "sticky",
        zIndex: 10,

        backgroundColor: "var(--background-color)",
        color: "black",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        padding: 8,
      }}
    >
      <div style={{ flex: 1 }}></div>
      {children}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <SubtleButton
          onClick={() => {
            // @ts-ignore
            if (window.Trengo?.Api?.Widget?.open) {
              // @ts-ignore
              window.Trengo.Api.Widget.open("help_center");
            } else {
              alert("Support is not available on this device.");
            }
          }}
        >
        </SubtleButton>
      </div>
    </div>
  );
};

function GameView() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let is_optimus_master_controller_prime =
    searchParams.get("is_optimus_master_controller_prime") === "";

  let { id, kist_naam } = useParams();
  let room_ref = ref(database, `rooms/${id}`);

  let room = useFirebase(room_ref) ?? {};

  React.useEffect(() => {
    console.debug(`room:`, room);
  }, [room]);

  let step = room?.step ?? 0;

  let t = useTranslation();

  React.useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  let date = useDate();
  let finished_time = room?.finished_time;
  let seconds_elapsed = differenceInSeconds(
    finished_time ?? date,
    room?.start_time
  );

  let time_from_90_minutes = HOW_LONG_DO_WE_HAVE_IN_SECONDS - seconds_elapsed;

  let is_development = kist_naam === DEVELOPMENT_PARK_NAME;

  if (time_from_90_minutes < 0) {
    return (
      <div
        className="height-height"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Whitespace height={32} />
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          {t(
            "Unfortunately, time is up. And you didn't manage to open the box this time. But see it as something good, you were together. Really together!"
          )}
        </div>
        <Whitespace height={32} />
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          <button
            onClick={() => {
              navigate("../photo-moment");
            }}
          >
            {t("Continue")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <Stack
      wrappers={[
        <IsOptimusMasterControllerPrimeContext.Provider
          value={is_optimus_master_controller_prime}
        />,
        <RoomContext.Provider value={room_ref} />,
        <IsDevelopmentContext.Provider value={is_development} />,
      ]}
    >
      <div
        className="height-height"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Topbar>
          <TimeThingy room={room} />
        </Topbar>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {step === 0 && (
            <StepSetTeamName
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                set(room_ref, {
                  kist_naam: kist_naam,
                  step: 2,
                  // Don't set the start time here!
                  // We only want to start the timer after the connect phones screen
                  // start_time: Date.now(),
                  timeout: 0,
                });
              }}
            />
          )}
          {step === 1 && (
            <StepConnectPhones
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, {
                  step: 2,
                  start_time: Date.now(),
                });
              }}
            />
          )}

          {step === 2 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 5 });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%201%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=60d6fd3e-43cf-485a-b6b0-94edd8d87095"
              )}
            />
          )}
          {step === 3 && (
            <Game1
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 4 });
              }}
            />
          )}
          {step === 4 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }

                update(room_ref, { step: 5 });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%202%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=e85c761a-5a26-459c-868f-b2f2483ff321"
              )}
            />
          )}
          {step === 5 && (
            <Game2
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 8 });
              }}
            />
          )}
          {step === 6 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 7 });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%203%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=4d844e63-dc7c-49d1-b618-5c8c9b4d0351"
              )}
            />
          )}
          {step === 7 && (
            <Game3
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 8 });
              }}
            />
          )}
          {step === 8 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 9 });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%204%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=80af3635-9e29-4f98-940d-4f1f23145df4"
              )}
            />
          )}
          {step === 9 && (
            <Game4
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 10 });
              }}
            />
          )}
          {step === 10 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 11 });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%205%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=f88703aa-d7e2-4a3c-8a01-2a0bf6a9345b"
              )}
            />
          )}
          {step === 11 && (
            <Game5
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, { step: 12 });
              }}
            />
          )}
          {step === 12 && (
            <PrevideoScreen
              onNext={() => {
                if (!is_optimus_master_controller_prime) {
                  window.alert(t("Only the gamemaster can control the game."));
                  return;
                }
                update(room_ref, {
                  step: 13,
                  finished_time: Date.now(),
                  total_time_spent_score:
                    differenceInSeconds(Date.now(), room.start_time ?? 0) +
                    (room.hints_penalty ?? 0) * 60,
                });
              }}
              url={t(
                "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%206%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=3788d22c-02d5-41ae-8b53-455dd4c324c5"
              )}
            />
          )}

          {/* {step === 13 && (
            <React.Suspense
              fallback={<SuspenseFallback message="TOPSCORE SUSPENSE" />}
            >
              <Topscore to="../photo-moment" />
            </React.Suspense>
          )} */}
          {step === 13 && <Topscore to="../photo-moment" />}
        </div>
      </div>
    </Stack>
  );
}

let Topscore = ({ to }) => {
  let navigate = useNavigate();
  let t = useTranslation();
  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);

  // TODO See other players score?
  // let rooms_slower = useFirebase(query(
  //   ref(database, `rooms`),
  //   orderByChild(`total_time_spent_score`),
  //   startAfter(room.total_time_spent_score ?? 0),
  //   limitToFirst(5)
  // ))
  // let rooms_faster = useFirebase(query(
  //   ref(database, `rooms`),
  //   orderByChild(`total_time_spent_score`),
  //   endBefore(room.total_time_spent_score ?? 0),
  //   limitToLast(5)
  // ))
  // let rooms = sortBy(
  //   Object.values({
  //     ...rooms_faster,
  //     ...rooms_slower,
  //   }).filter((x) => x.total_time_spent_score != null),
  //   (x) => x.total_time_spent_score
  // );

  let seconds_elapsed = differenceInSeconds(
    room.finished_time ?? 0,
    room.start_time ?? 0
  );

  let hints_penalty = room.hints_penalty ?? 0;

  return (
    <div style={{ padding: 32 }}>
      {t("You finished in")}{" "}
      <span style={{ color: "green" }}>
        <span>
          {(seconds_elapsed / 60).toFixed(0)} {t("minutes")}
        </span>{" "}
        {t("and")}{" "}
        <span>
          {(seconds_elapsed % 60).toFixed(0).padStart(2, "0")} {t("seconds")}
        </span>
      </span>{" "}
      {hints_penalty === 0 ? (
        <span>{t("without using any hints!")}</span>
      ) : (
        <span>
          {t("with")}{" "}
          <span style={{ color: "red" }}>
            {room.hints_penalty ?? 0} {t("minutes")}
          </span>{" "}
          {t("penalty for using hints!")}
        </span>
      )}
      <Whitespace height={16} />
      <button onClick={() => navigate(to)}>{t("Continue")}</button>
    </div>
  );
};

let StepSetTeamName = ({ onNext }) => {
  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);
  let t = useTranslation();

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-around",
      }}
    >
      <DevButton onClick={onNext} />

      <Whitespace height={16} />
      <h1 style={{ textAlign: "center", fontSize: 60 }}>{t("Start de KNVB Game")}</h1>
      <Whitespace height={16} />
      <div
        style={{
          backgroundColor: "white",
          margin: 16,
          padding: "20px 25px",
          borderRadius: 20,
          boxShadow: "rgb(0 0 0 / 6%) 0px 1px 12px",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <b style={{ color: "black", fontSize: 40 }}>
          {t("Verdeel samen met jullie begeleiders de teams. Zorg ervoor dat er in alle teams ongeveer evenveel voetballers zitten. En maak je niet te veel zorgen om bij wie je zit. We spelen dit spel namelijk allemaal samen.")}
        </b>
        <Whitespace height={16} />
      </div>

      <Whitespace height={16} />

      <div style={{ margin: 16 }}>
        <button onClick={onNext}>{t("Continue")}</button>
      </div>

      <Whitespace height={16} />
    </div>
  );
};

let StepConnectPhones = ({ onNext }) => {
  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);
  let t = useTranslation();
  let is_master = React.useContext(IsOptimusMasterControllerPrimeContext);

  let location_without_query_params = new URL(window.location.href);
  location_without_query_params.search = "";

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-around",
      }}
    >
      <DevButton onClick={onNext} />

      <h1 style={{ textAlign: "center" }}>{t("The Box")}</h1>
      <div style={{ minHeight: 16 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 25,
          borderRadius: 20,
          boxShadow: "rgb(0 0 0 / 6%) 0px 1px 12px",
          marginLeft: 16,
          marginRight: 16,

          display: "flex",
          flexDirection: "column",
        }}
      >
        <QRCode
          value={location_without_query_params.toString()}
          style={{ height: 200, alignSelf: "center" }}
        />
        <div style={{ marginTop: 16 }}>
          {t("Scan the qr code below to play on your own phone.")}
        </div>
        {navigator.share != null && (
          <div style={{ width: 200, marginTop: 16 }}>
            <a
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              href="#"
              onClick={() => {
                navigator.share({ url: window.location.toString() });
              }}
            >
              <IosShare />
              <div style={{ width: 8 }} />
              {t("Share game")}
            </a>
          </div>
        )}
      </div>
      <div style={{ minHeight: 16 }} />
      <div style={{ marginLeft: 16, marginRight: 16 }}>
        <button disabled={!is_master} onClick={onNext}>
          {t("Start Game")}
        </button>
      </div>

      <Whitespace height={8} />
    </div>
  );
};

let InlineVideo = ({ src, height = undefined, width = undefined }) => {
  return (
    <div
      style={{
        borderRadius: 5,
      }}
    >
      <video
        width={width}
        height={height}
        preload="metadata"
        onClick={(e) => {
          let video = /** @type {HTMLVideoElement} */ (e.currentTarget);
          // video.muted = !video.muted;

          // if (video.paused) {
          //   video.play();
          // }

          // @ts-ignore
          // video.webkitEnterFullScreen?.();
          // video.requestFullscreen?.();
        }}
        onPause={(e) => {
          // let video = /** @type {HTMLVideoElement} */ (e.currentTarget);
          // video.muted = true;
          // video.play();
        }}
        // muted={true}
        playsInline={true}
        // autoPlay
        controls
        // loop
        style={{
          borderRadius: 20,
          width: "100%",
          backgroundColor: "black",
        }}
        src={`${src}#t=0.1`}
      />
    </div>
  );
};

let Video = ({ url, onNext }) => {
  let is_master = React.useContext(IsOptimusMasterControllerPrimeContext);
  return (
    <div
      style={{
        borderRadius: 5,
      }}
    >
      <video
        preload="metadata"
        onClick={(e) => {
          let video = /** @type {HTMLVideoElement} */ (e.currentTarget);
          video.play();
          video.muted = false;
        }}
        controls
        onEnded={(e) => {
          // @ts-ignore
          e.currentTarget.webkitExitFullscreen?.();
          onNext();
        }}
        style={{
          borderRadius: 20,
          width: "100%",
          backgroundColor: "black",
        }}
        src={`${url}`}
      />
    </div>
  );
};

let SKIP_VIDEO_TIMEOUT = 10 * 1000;

let PrevideoScreen = ({ onNext, url }) => {
  let is_timeout = useTimeout(SKIP_VIDEO_TIMEOUT);
  let [is_done_watching, set_is_done_watching] = React.useState(false);
  let t = useTranslation();
  let is_dev = useIsDevelopment();

  return (
    <div style={{ margin: 16 }}>
      <DevButton onClick={onNext} />

      <div>{t("FRAGMENT 1")}</div>
      <Whitespace height={16} />
      <Video
        url="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/KNVB%2FVooraf.mp3?alt=media&token=56be9cfb-76a8-4022-a61e-8565f40d2f81"
        onNext={() => {
          set_is_done_watching(true);
        }}
        
      />
         <div>{t("FRAGMENT 2")}</div>
      <Whitespace height={16} />
            <Video
        url="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/KNVB%2FKNVB%201.mp3?alt=media&token=0d69296b-c7f1-4112-9533-87f06f2c3c01"
        onNext={() => {
          set_is_done_watching(true);
        }}
      />
      <Whitespace height={16} />
      {is_done_watching ? (
        <button
          onClick={onNext}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {t("Continue")}
          <Whitespace width={8} />
          <ArrowForward style={{ transform: "translateY(1px)" }} />
        </button>
      ) : is_timeout ? (
        <button onClick={onNext}>{t("Skip video")}</button>
      ) : null}
    </div>
  );
};

let Game1 = ({ onNext }) => {
  let t = useTranslation();
  let dialog_1 = useDialogRef();
  let previous_media_dialog = useDialogRef();

  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);
  let is_master = React.useContext(IsOptimusMasterControllerPrimeContext);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <DevButton onClick={onNext} />

      <PreviousMediaDialog
        ref={previous_media_dialog}
        url={t(
          "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%201%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=60d6fd3e-43cf-485a-b6b0-94edd8d87095"
        )}
      />

      <HintsDialog
        ref={dialog_1}
        hints={[
          {
            bought: room?.bought_hints?.hint_1a,
            penalty_minutes: 1,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_1a"), true);
            },
            text: t(
              "Fold each canvas in the box in the right order. Which character do you see?"
            ),
          },
          {
            bought: room?.bought_hints?.hint_1b,
            penalty_minutes: 3,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_1b"), true);
            },
            text: t(
              "Fold the empty triangle onto the full triangle, empty asterisk onto the full asterisk, etc. Do this in the order shown on the box. Which character do you see?"
            ),
          },

          {
            bought: room?.bought_hints?.hint_1c,
            penalty_minutes: 5,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_1c"), true);
            },
            text: (
              <div>
                {t(
                  "Press the following characters and open the door on the box:"
                )}
                <Whitespace height={8} />
                <img
                  style={{ width: "100%", filter: "invert(1)" }}
                  // @ts-ignore
                  src={require("./assets/spel-1-hint-3.svg").default}
                />
                <Whitespace height={8} />
                <InlineVideo src="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Laatste%20hint%2F1.%20Pieletjes.mp4?alt=media&token=8c25e94f-47fe-448a-bdad-c8c980e06104" />
              </div>
            ),
          },
        ]}
      />

      <QrCameraView code="https://qrty.io/VpQINz" onScan={onNext} />

      <div
        style={{
          flex: 1,
          // flex center
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <button
          className="outline"
          onClick={() => {
            previous_media_dialog.current.showModal();
          }}
        >
          {t("Video")}
        </button>
        <div style={{ width: 16 }} />
        <button onClick={() => dialog_1.current.showModal()}>
          {t("Hints")}
        </button>
      </div>
    </div>
  );
};

let VALID_PHONE_NUMBERS = [
  "+31614746053",
  "+11111111111111",
];

/**
 * @typedef THint
 * @type {{
 *  text: string,
 *  bought: boolean,
 *  penalty_minutes: number,
 *  onBuy: () => void,
 * }}
 */

let HintsDialog = React.forwardRef(
  (/** @type {{ hints: THint[] }} */ { hints }, forward_ref) => {
    let room_ref = React.useContext(RoomContext);
    let room = useFirebase(room_ref);
    let t = useTranslation();
    let is_master = React.useContext(IsOptimusMasterControllerPrimeContext);

    return (
      <Dialog
        className="fancy-backdrop"
        onClick={(e) => {
          // @ts-ignore
          if (e.target === e.currentTarget) forward_ref.current.close();
        }}
        ref={forward_ref}
        style={{
          color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: 16,

            margin: "auto",

            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 tabIndex={-1}>{t("Hints")}</h1>
            <Close
              onClick={() => {
                // @ts-ignore
                forward_ref.current.close();
              }}
            />
          </div>
          {hints.map((hint, i) => (
            <div
              key={i}
              style={{
                minWidth: "100%",
                backgroundColor: "rgba(0,0,0,.2)",

                // Flex center
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                padding: 16,
                marginBottom: 16,
              }}
            >
              {hint.bought ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {t("Hint")} {i + 1}
                    </span>
                  </div>
                  <div>{hint.text}</div>
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    if (!is_master) {
                      window.alert("Only the game master can buy hints!");
                      return;
                    }

                    if (
                      window.confirm(
                        t("Are you sure you want to buy this hint?")
                      )
                    ) {
                      set(
                        child(room_ref, "hints_penalty"),
                        (room.hints_penalty ?? 0) + hint.penalty_minutes
                      );
                      hint.onBuy();

                      let hint_div = e.currentTarget;
                      setTimeout(() => {
                        hint_div.scrollIntoView?.({
                          behavior: "smooth",
                          block: "center",
                        });
                      }, 100);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lock />
                    <div style={{ width: 8 }} />
                    <span style={{ fontSize: "1.5em" }}>
                      {t("Hint")} {i + 1}
                    </span>
                  </div>
                  <div>
                    {t("Click to unlock hint, will add")}{" "}
                    <b>
                      {hint.penalty_minutes} {t("minutes")}
                    </b>{" "}
                    {t("to your score.")}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Dialog>
    );
  }
);

let PreviousMediaDialog = React.forwardRef(
  (/** @type {{ url: string }} */ { url }, forward_ref) => {
    let room_ref = React.useContext(RoomContext);
    let room = useFirebase(room_ref);
    let t = useTranslation();

    return (
      <Dialog
        className="fancy-backdrop"
        onClick={(e) => {
          // @ts-ignore
          if (e.target === e.currentTarget) e.target.close();
        }}
        ref={forward_ref}
        style={{
          color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: 16,

            margin: "auto",

            width: "100%",

            backgroundColor: "white",
            padding: 8,
          }}
        >
          <video
            controls
            preload="metadata"
            // autoPlay
            playsInline={false}
            // onEnded={() => {
            //   onNext();
            // }}
            style={{
              width: "100%",
              borderRadius: 8,
            }}
            src={`${url}#t=1`}
          />
        </div>
      </Dialog>
    );
  }
);

let Game2 = ({ onNext }) => {
  let [showing_hint, set_showing_hint] = React.useState(null);

  let dialog_1 = useDialogRef();
  let previous_media_dialog = useDialogRef();

  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);
  let t = useTranslation();
  let is_master = React.useContext(IsOptimusMasterControllerPrimeContext);

  let good_number_dialog_ref = useDialogRef();
  /** @type {import("react").MutableRefObject<HTMLAudioElement>} */
  let good_number_audio_ref = React.useRef();

  let wrong_number_dialog_ref = useDialogRef();
  /** @type {import("react").MutableRefObject<HTMLAudioElement>} */
  let wrong_number_audio_ref = React.useRef();

  let phone_number = room?.phone_number ?? "";

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-around",
      }}
    >
      <DevButton onClick={onNext} />

      <PreviousMediaDialog
        ref={previous_media_dialog}
        url={t(
          "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%202%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=e85c761a-5a26-459c-868f-b2f2483ff321"
        )}
      />
      <HintsDialog
        ref={dialog_1}
        hints={[
          {
            bought: room?.bought_hints?.hint_2a,
            penalty_minutes: 1,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_2a"), true);
            },
            text: t("Examine the box with the mirror."),
          },
          {
            bought: room?.bought_hints?.hint_2b,
            penalty_minutes: 3,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_2b"), true);
            },
            text: t("How much do the letters C, E, F and H weigh?"),
          },

          {
            bought: room?.bought_hints?.hint_2c,
            penalty_minutes: 5,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_2c"), true);
            },
            text: (
              <div>
                {t("The code of the next box is:")}
                {/* <img
                  style={{ width: "100%", filter: "invert(1)", marginTop: 8 }}
                  // @ts-ignore
                  src={require("./assets/spel-2-hint-3.svg").default}
                /> */}
                <div
                  style={{
                    display: "grid",
                    gridTemplate: `
                      "a b"
                      "c d"
                      / 1fr 1fr
                    `,
                    textAlign: "center",
                    fontSize: `6rem`,
                  }}
                >
                  <div style={{ gridArea: "a" }}>3</div>
                  <div style={{ gridArea: "b" }}>2</div>
                  <div style={{ gridArea: "c" }}>6</div>
                  <div style={{ gridArea: "d" }}>7</div>
                </div>
                <Whitespace height={8} />
                <InlineVideo src="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Laatste%20hint%2F2.%20Draaipiel.mp4?alt=media&token=94c9af40-4123-411a-8dd7-97e580d18a2b" />
              </div>
            ),
          },
        ]}
      />

      <PhoneNumberView
        value={phone_number}
        disabled={!is_master}
        onChange={(new_phone_number) => {
          if (new_phone_number.length < 15) {
            set(child(room_ref, "phone_number"), new_phone_number);
          } else {
            // Phone number too long
          }
        }}
        onCall={() => {
          if (VALID_PHONE_NUMBERS.includes(`+${phone_number}`)) {
            good_number_dialog_ref.current.showModal();
          } else {
            wrong_number_dialog_ref.current.showModal();
          }
        }}
      />

      <GoodPhoneCallScreen
        onNext={onNext}
        phone_number={phone_number}
        ref={good_number_dialog_ref}
      />

      <WrongPhoneCallScreen
        phone_number={phone_number}
        ref={wrong_number_dialog_ref}
      />

      <div
        style={{
          // flex center
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "space-between",
          // maxWidth: 250,
          // margin: "0 auto",
          margin: "0 16px",
        }}
      >
      </div>
    </div>
  );
};

let Game3 = ({ onNext }) => {
  let t = useTranslation();
  let dialog_1 = useDialogRef();
  let previous_media_dialog = useDialogRef();

  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <DevButton onClick={onNext} />

      <PreviousMediaDialog
        ref={previous_media_dialog}
        url={t(
          "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%203%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=4d844e63-dc7c-49d1-b618-5c8c9b4d0351"
        )}
      />
      <HintsDialog
        ref={dialog_1}
        hints={[
          {
            bought: room?.bought_hints?.hint_3a,
            penalty_minutes: 1,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_3a"), true);
            },
            text: t(
              "The text mentions two locations. Go outside and look for these locations."
            ),
          },
          {
            bought: room?.bought_hints?.hint_3b,
            penalty_minutes: 3,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_3b"), true);
            },
            text: t(
              "Look for signs in these locations? Do you see the signs on the box?"
            ),
          },

          {
            bought: room?.bought_hints?.hint_3c,
            penalty_minutes: 5,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_3c"), true);
            },
            text: (
              <div>
                {t(
                  "Slide the sliding compartment open. First put the magnet on the first sign. Then put the magnet on the second sign while you slide the box open."
                )}
                <Whitespace height={8} />
                <InlineVideo src="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Laatste%20hint%2F3.%20Magneten.mp4?alt=media&token=918379ec-2060-47ed-b81a-37b86edfaf8a" />
              </div>
            ),
          },
        ]}
      />
      <QrCameraView code="https://qrty.io/TQMNQa" onScan={onNext} />
      <div
        style={{
          flex: 1,
          // flex center
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <button
          className="outline"
          onClick={() => {
            previous_media_dialog.current.showModal();
          }}
        >
          {t("Video")}
        </button>
        <div style={{ width: 16 }} />
        <button onClick={() => dialog_1.current.showModal()}>
          {t("Hints")}
        </button>
      </div>
    </div>
  );
};

let Game4 = ({ onNext }) => {
  let t = useTranslation();
  let dialog_1 = useDialogRef();
  let previous_media_dialog = useDialogRef();

  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <DevButton onClick={onNext} />

      <PreviousMediaDialog
        ref={previous_media_dialog}
        url={t(
          "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%204%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=80af3635-9e29-4f98-940d-4f1f23145df4"
        )}
      />

      <HintsDialog
        ref={dialog_1}
        hints={[
          {
            bought: room?.bought_hints?.hint_4a,
            penalty_minutes: 1,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_4a"), true);
            },
            text: t(
              "Slide the magnet over the back of the box, following the instructions."
            ),
          },
          {
            bought: room?.bought_hints?.hint_4b,
            penalty_minutes: 3,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_4b"), true);
            },
            text: t(
              "When you reach the end of the journey, open the back of the box."
            ),
          },

          {
            bought: room?.bought_hints?.hint_4c,
            penalty_minutes: 5,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_4c"), true);
            },
            text: (
              <div>
                {t("The order of the signs is:")}
                <img
                  height={273}
                  width={87}
                  style={{ width: "100%", filter: "invert(1)", marginTop: 8 }}
                  // @ts-ignore
                  src={require("./assets/spel-4-hint-3.svg").default}
                />
                <Whitespace height={8} />
                <InlineVideo
                  width={3840}
                  height={2160}
                  src="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Laatste%20hint%2F4.%20Doolhof.mp4?alt=media&token=1d377f6c-5994-4fc3-9fde-8e85889aa839"
                />
              </div>
            ),
          },
        ]}
      />

      <QrCameraView code="https://qrty.io/JqQQgS" onScan={onNext} />

      <div
        style={{
          flex: 1,
          // flex center
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <button
          className="outline"
          onClick={() => {
            previous_media_dialog.current.showModal();
          }}
        >
          {t("Video")}
        </button>
        <div style={{ width: 16 }} />
        <button onClick={() => dialog_1.current.showModal()}>
          {t("Hints")}
        </button>
      </div>
    </div>
  );
};

let Game5 = ({ onNext }) => {
  let t = useTranslation();
  let dialog_1 = useDialogRef();
  let previous_media_dialog = useDialogRef();

  let room_ref = React.useContext(RoomContext);
  let room = useFirebase(room_ref);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <DevButton onClick={onNext} />

      <PreviousMediaDialog
        ref={previous_media_dialog}
        url={t(
          "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%205%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=f88703aa-d7e2-4a3c-8a01-2a0bf6a9345b"
        )}
      />

      <HintsDialog
        ref={dialog_1}
        hints={[
          {
            bought: room?.bought_hints?.hint_5a,
            penalty_minutes: 1,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_5a"), true);
            },
            text: t("Start from the drawn position."),
          },
          {
            bought: room?.bought_hints?.hint_5b,
            penalty_minutes: 3,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_5b"), true);
            },
            text: t(
              "The number of steps to be taken to turn the disc is indicated by the hands of the clocks."
            ),
          },

          {
            bought: room?.bought_hints?.hint_5c,
            penalty_minutes: 5,
            onBuy: () => {
              set(child(room_ref, "bought_hints/hint_5c"), true);
            },
            text: (
              <div>
                {t(
                  "After turning the three discs, you get the following code."
                )}
                <img
                  style={{
                    width: "100%",
                    filter: "invert(1)",
                    marginTop: 8,
                    borderRadius: 20,
                  }}
                  // @ts-ignore
                  src={require("./assets/spel-5-hint-3.svg").default}
                />
                <Whitespace height={8} />
                <InlineVideo src="https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Laatste%20hint%2F5.%20Draaischijf.mp4?alt=media&token=7a750928-0357-46be-9654-1821ee968790" />
              </div>
            ),
          },
        ]}
      />

      <QrCameraView code="https://qrty.io/bN2ybC" onScan={onNext} />

      <div
        style={{
          flex: 1,
          // flex center
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <button
          className="outline"
          onClick={() => {
            previous_media_dialog.current.showModal();
          }}
        >
          {t("Video")}
        </button>
        <div style={{ width: 16 }} />
        <button onClick={() => dialog_1.current.showModal()}>
          {t("Hints")}
        </button>
      </div>
    </div>
  );
};

let SelfieAndPostScreen = ({ to }) => {
  let t = useTranslation();
  let navigate = useNavigate();
  /** @type {React.MutableRefObject<HTMLInputElement>} */
  let input_ref = React.useRef();

  let [file_and_dataurl, set_file] = React.useState(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Topbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: 16,
        }}
      >
        <h2 style={{ margin: 0 }}>
          {t("If you cannot be a star in the sky, be a light in your house.")}
        </h2>
        <Whitespace height={16} />
        {file_and_dataurl == null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flex: "0 200px",
              backgroundColor: "#e9e9e9",
            }}
            onClick={() => {
              input_ref.current.click();
            }}
          >
            <PhotoCamera />
            <div style={{ width: 8 }} />
            <div>{t("Snap a picture")}</div>
          </div>
        ) : (
          <a
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flex: "0 200px",
              backgroundColor: "#e9e9e9",
              backgroundImage: `url(${file_and_dataurl[1]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: `white`,
            }}
            href="#"
            onClick={() => {
              navigator.share({ files: [file_and_dataurl[0]] });
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,.8)",
                borderRadius: 20,
                padding: 16,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <IosShare />
              <div style={{ width: 8 }} />
              {t("Share Picture")}
            </div>
          </a>
        )}
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (e.target.files.length > 0) {
              let file = e.target.files[0];
              let reader = new FileReader();
              reader.onload = function (e) {
                set_file([file, e.target.result]);
              };
              reader.readAsDataURL(file);

              // set_file(file);
            }
          }}
          ref={input_ref}
        />

        <div style={{ flex: 1, marginTop: 20 }} />

        <button onClick={() => navigate(`../${to}`)}>
          {t("Cleanup Instructions")}
        </button>

        <Whitespace height={32} />

        <img
          // @ts-ignore
          src={require("./assets/KNVB_Logo.png").default}
          style={{
            width: "100%",
            maxWidth: 200,
            alignSelf: `center`,
            transform: `translateY(10px)`,
          }}
        />
        <div style={{ alignSelf: "center" }}>Made by</div>
        <img
          src="https://expinc.io/wp-content/uploads/2020/11/Geelkopie-300x68.png"
          style={{ width: 100, alignSelf: "center", marginTop: 4 }}
        />
      </div>
    </div>
  );
};

let Titlebar = styled.h1`
  /* background-color: var(--accent-color);
  color: white; */
  top: 0px;
  /*position: sticky;*/
  margin: -16px;
  margin-bottom: 0px;
  padding: 16px;
`;

let CleanupScreen = ({ to }) => {
  let t = useTranslation();
  let navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Topbar />

      <div style={{ margin: 16 }}>
        <Titlebar>{t("Bring back the box")}</Titlebar>
        <Whitespace height={16} />
        <div
          style={{
            display: "grid",
            gap: 16,
            gridTemplate: `
            "a"
            "b"
            "c"
            "d"
            "e"
            "f"
            "g"
            "h"
            / 1fr
          `,
          }}
        >
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_1.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 1<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_2.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />

          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 2<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_3.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 3<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_4.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 4<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_5.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 5<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_6.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 6<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_7.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 7<p>&nbsp;</p>
          </div>
          <img
            // @ts-ignore
            src={require("./assets/cleanup_stap_8.gif")}
            style={{ width: "100%", objectFit: "contain", borderRadius: 10 }}
          />
          <div style={{ width: "100%", objectFit: "contain" }}>
            {t("Step")} 8<p>&nbsp;</p>
          </div>
        </div>
        <Whitespace height={16} />
        {to && (
          <button
            onClick={() => {
              navigate(`../${to}`);
            }}
          >
            {t("Done")}
          </button>
        )}
        <Whitespace height={16} />
      </div>
    </div>
  );
};

let CreditsPage = () => {
  let t = useTranslation();
  return (
    <div style={{ margin: 30 }}>
      <h1 style={{ margin: 0 }}>{t("Credits")}</h1>
      <div
        style={{
          width: 300,
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <div>
          <strong>Concept</strong>: Exp Inc.
        </div>
        <div>
          <strong>Productdesign</strong>: Exp Inc.
        </div>
        <div>
          <strong>App</strong>: Michiel Dral en Exp Inc.
        </div>
        <div>
          <strong>Gamedesign: </strong>Exp Inc.
        </div>
        <div>
          <strong>Director</strong>: Dick van den Heuvel
        </div>
        <div>
          <strong>Assistent regie</strong>: Gerda van Roshum
        </div>
        <div>
          <strong>Actor</strong>: Kok-Hwa Lie
        </div>
        <div>
          <strong>Make-Up</strong>: Bobby Renooij
        </div>
        <div>
          <strong>Clothes</strong>: Natasja Kerkvliet
        </div>
        <div>
          <strong>Camera</strong>: Maarten Kramer
        </div>
        <div>
          <strong>Thanks to</strong>: Martijn Dijkstra, Gerwin Overeem
        </div>
      </div>
    </div>
  );
};

let NoGame = () => {
  if (process.env.NODE_ENV === "development") {
    return <Navigate to={`/${DEVELOPMENT_PARK_NAME}`} />;
  }

  return <div>Nothing to see here :(</div>;
};

let MaakGame = () => {
  let { kist_naam, language } = useParams();
  return (
    <Navigate
      to={`/${kist_naam}/${language}/game/${uuidv4()}?is_optimus_master_controller_prime`}
    />
  );
};

let SplashScreen = () => {
  let { kist_naam } = useParams();
  let t = useTranslation();
  let navigate = useNavigate();

  return (
    <div
      className="height-height"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        overflow: "scroll",
      }}
    >
      <div style={{ minHeight: 16 }} />
      <div
        style={{
          textAlign: "center",
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <h2>{t("KNVB Game")}</h2>
      </div>
      <img
        // @ts-ignore
        src={require("./assets/splashscreen.png")}
        style={{
          width: "60%",
          height: "60%",
          objectFit: "cover",
          borderRadius: 10,
          alignSelf: "center",
          aspectRatio: "1.77",
          backgroundColor: `var(--background-color)`,
        }}
      />
      <Whitespace height={24} />
      <div
        style={{
          padding: 16,
        }}
      >
        <button
          onClick={() => {
            navigate("play" + window.location.search);
          }}
        >
          {t("Enter")}
        </button>
      </div>
      <img
        // @ts-ignore
        src={require("./assets/KNVB_Logo.png").default}
        style={{
          width: "100%",
          padding: 16,
          maxWidth: 200,
          alignSelf: `center`,
          transform: `translateY(10px)`,
        }}
      />
      <div style={{ alignSelf: "center" }}>Made by</div>
      <img
        src="https://expinc.io/wp-content/uploads/2020/11/Geelkopie-300x68.png"
        style={{ width: 100, alignSelf: "center", marginTop: 4 }}
      />
    </div>
  );
};

let SuspenseFallback = ({ message = `SAY WHAAAT, SUSPENSING!` }) => {
  return <div></div>;
};

function App() {
  let is_connected = useConnected(database);

  let [was_connected, set_was_connected] = React.useState(false);
  React.useLayoutEffect(() => {
    if (!was_connected) {
      set_was_connected(is_connected);
    }
  }, [is_connected]);

  if (!is_connected) {
    if (!was_connected) {
      return (
        <div
          className="height-height"
          style={{ backgroundColor: "var(--background-color)" }}
        >
          <div style={{ padding: 32 }}>Reconnecting...</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  return (
    <div
      className="height-height"
      style={{ backgroundColor: "var(--background-color)" }}
    >
      <CssBaseline />
      <React.Suspense fallback={<SuspenseFallback />}>
        <BrowserRouter>
          <Routes>
            <Route index element={<NoGame />} />
            <Route path=":kist_naam/" element={<LanguagesView />} />
            <Route path=":kist_naam/:language" element={<MaakGame />} />
            <Route path=":kist_naam/:language/game" element={<MaakGame />} />
            <Route path=":kist_naam/:language/game/:id">
              <Route index element={<SplashScreen />} />
              <Route path="play" element={<GameView />} />
              <Route
                path="photo-moment"
                element={<SelfieAndPostScreen to="cleanup" />}
              />
              <Route path="cleanup" element={<CleanupScreen to="credits" />} />
              <Route path="credits" element={<CreditsPage />} />
            </Route>
            <Route path="cleanup" element={<CleanupScreen to={null} />} />
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </div>
  );
}

export default App;

import { with_translation } from "./utils/use";

export let translations = {
  "The Box": {
    en: "The Box",
    fr: "La boîte",
    de: "Die Kiste",
    nl: "De kist",
  },
  "The box of": {
    en: "The box of",
    fr: "La boîte de",
    de: "Die Kiste von",
    nl: "De kist van",
  },
  Enter: {
    en: "Enter",
    fr: "Continuer",
    de: "Weiter",
    nl: "Verder",
  },
  "Team Name": {
    en: "Team Name",
    fr: "Nom d'équipe",
    de: "Team Name",
    nl: "Teamnaam",
  },
  "Scan the qr code below to play on your own phone.": {
    en: "Fellow players can scan this QR code to watch videos on their own phones. When everyone has scanned the QR code you can start the game. PLEASE NOTE: you then have 120 minutes to open the chest.",
    fr: "Les autres joueurs peuvent scanner ce code QR pour regarder les vidéos sur leur propre téléphone. Lorsque tout le monde a scanné le code QR, vous pouvez commencer le jeu. VEUILLEZ NOTER : vous avez ensuite 120 minutes pour ouvrir le coffre.",
    de: "Mitspieler können diesen QR-Code scannen, um sich die Videos auf ihren eigenen Handys anzusehen. Wenn alle den QR-Code gescannt haben, können Sie das Spiel starten. BITTE BEACHTEN SIE: Sie haben dann 120 Minuten Zeit, um die Truhe zu öffnen.",
    nl: "Medespelers kunnen deze  QR code scannen om video's mee te kijken op hun eigen telefoon. Wanneer iedereen de QR code heeft gescand kun je het spel starten. LET OP: je hebt vanaf dan 120 minuten de tijd om de kist te openen.",
  },
  "Choose a name for your team": {
    en: "Choose a name for your team",
    nl: "Kies een naam voor je team",
    fr: "Choisissez un nom pour votre équipe",
    de: "Wählen Sie einen Namen für Ihr Team",
  },
  "Start Game": {
    en: "Start Game",
    nl: "Start Spel",
    fr: "Commencer le jeu",
    de: "Spiel starten",
  },
  "Share game": {
    en: "Share game",
    nl: "Deel spel",
    fr: "Partager le jeu",
    de: "Spiel teilen",
  },
  "Waiting for camera...": {
    en: "Waiting for camera...",
    nl: "Wachten op camera...",
    fr: "En attente de la caméra...",
    de: "Warten auf Kamera...",
  },
  "Enter Phone Number": {
    en: "Enter Phone Number",
    nl: "Bel de juiste speler +316147460 _ _",
    fr: "Numéro de téléphone",
    de: "Telefonnummer",
  },
  "If you cannot be a star in the sky, be a light in your house.": {
    en: "If you cannot be a star in the sky, be a light in your house.",
    nl: "Als je geen ster aan de hemel kunt zijn, wees dan een lampje in je huis.",
    fr: "Si vous ne pouvez pas être une étoile dans le ciel, soyez une lumière dans votre maison.",
    de: "Wenn du nicht ein Stern am Himmel sein kannst, dann sei ein Licht in deinem Haus.",
  },
  "Watch the video to continue to the next step.": {
    en: "Watch the video to continue to the next step.",
    nl: "Bekijk de video om verder te gaan naar de volgende stap.",
    fr: "Regardez la vidéo pour continuer à l'étape suivante.",
    de: "Schauen Sie die Video um zum nächsten Schritt zu weiterschalten.",
  },
  "Skip video": {
    en: "Skip video",
    nl: "Ga verder",
    fr: "Passer la vidéo",
    de: "Video überspringen",
  },
  Video: {
    en: "Video",
    nl: "Video",
    fr: "Vidéo",
    de: "Video",
  },
  Hints: {
    en: "Hints",
    nl: "Hints",
    fr: "Astuces",
    de: "Tipps",
  },
  "Click to unlock hint, will add": {
    en: "Click to unlock hint, this will add",
    nl: "Klik om de hint te ontgrendelen, dat zal",
    fr: "Cliquez pour débloquer l'indice, sa ajoutera",
    de: "Klicken Sie um den Tipp zu entsperren, das wird",
  },
  minutes: {
    en: "minutes",
    nl: "minuten",
    fr: "minutes",
    de: "Minuten",
  },
  "to your score.": {
    en: "to your score.",
    nl: "aan je score toevoegen.",
    fr: "à votre score.",
    de: "zu Ihrer Punktzahl hinzugefügt.",
  },
  "Are you sure you want to buy this hint?": {
    en: "Are you sure you want the hint? It will affect your score!",
    nl: "Weet je zeker dat je de hint wil? Dat heeft invloed op je score!",
    fr: "Vous êtes sûr de vouloir l'indice ? Cela affectera votre score !",
    de: "Sind Sie sicher, dass Sie diesen Tipp kaufen möchten?",
  },
  "You have 90 minutes to finish the game. You'll see the time you have left in the top of the screen.":
    {
      en: "You have 90 minutes to finish the game. You'll see the time you have left on the top of the screen.",
      nl: "Je hebt 90 minuten om het spel te voltooien. Je ziet de tijd die je nog over hebt aan de bovenkant van het scherm.",
      fr: "Vous avez 90 minutes pour terminer le jeu. Vous verrez le temps que vous avez resté dans la partie supérieure de l'écran.",
      de: "Sie haben 90 Minuten um das Spiel zu beenden. Sie sehen die verbleibende Zeit auf der oberen Seite des Bildschirms.",
    },
  "For every hint you unlock, there will be some time added to your final score. You will still be able to play the full 90 minutes, but your final score will be a bit less.":
    {
      en: "For every hint you unlock, there will be some time added to your final score. You will still be able to play the full 90 minutes, but your final score will be a bit less.",
      nl: "Voor elke hint die je ontgrendelt, zal er een paar minuten toegevoegd worden aan je eindscore. Je zal nog steeds de volledige 90 minuten spelen, maar je eindscore zal iets minder zijn.",
      fr: "Pour chaque indice que vous débloquez, il y aura un peu de temps ajouté à votre score final. Vous pourrez toujours jouer le jeu complet de 90 minutes, mais votre score final sera un peu moins.",
      de: "Für jeden Tipp, den Sie entsperren, wird einige Zeit zu Ihrer Endpunktzahl hinzugefügt. Sie können dennoch die ganze 90 Minuten spielen, aber Ihre Endpunktzahl wird etwas weniger sein.",
    },
  "Share Picture": {
    en: "Share Picture",
    nl: "Deel foto",
    fr: "Partager l'image",
    de: "Bild teilen",
  },
  "Bring back the box": {
    en: "Return the box",
    nl: "Breng de kist terug",
    fr: "Ramenez le coffre",
    de: "Bringe das Feld zurück",
  },
  "Fold each canvas in the box in the right order. Which character do you see?":
    {
      en: "Fold each canvas in the box in the right order. Which character do you see?",
      nl: "Vouw elk doek in de kist op de juiste volgorde. Welk teken houd je over?",
      fr: "Pliez chaque tissu de la coffre dans le bon ordre. Quel personnage gardez-vous ?",
      de: "Falten Sie jedes Tuch in die Schachtel. Welche Figur behalten Sie?",
    },
  "Fold the empty triangle onto the full triangle, empty asterisk onto the full asterisk, etc. Do this in the order shown on the box. Which character do you see?":
    {
      en: "Fold the empty triangle onto the full triangle, empty asterisk onto the full asterisk, etc. Do this in the order shown on the box. Which character do you see?",
      nl: "Vouw de lege driehoek op de volle driehoek, lege sterretje op het volle sterretje etc. Doe dit op de volgorde zoals aangegeven is in de box. Welk teken hou je over?",
      fr: "Repliez le triangle vide sur le triangle plein, l'étoile vide sur l'étoile pleine, etc. Faites-le dans l'ordre indiqué dans le coffre. Quel personnage gardez-vous ?",
      de: "Falten Sie das leere Dreieck auf das volle Dreieck, den leeren Stern auf den vollen Stern, usw. Gehen Sie dabei in der im Kasten angegebenen Reihenfolge vor. Welche Figur behalten Sie?",
    },
  "Press the following characters and open the door on the box:": {
    en: "Press the following characters and open the door:",
    nl: "Druk de volgende tekens in en open het deurtje:",
    fr: "Appuyez sur les caractères suivants et ouvrez la porte:",
    de: "Drücken Sie die folgenden Zeichen und öffnen Sie die Tür, um sie zu öffnen:",
  },
  "Examine the box with the mirror.": {
    en: "Examine the box with the mirror.",
    nl: "Onderzoek de kist met de spiegel.",
    fr: "Examinez le coffre avec le miroir.",
    de: "Untersuche die Truhe mit dem Spiegel.",
  },
  "How much do the letters C, E, F and H weigh?": {
    en: "How much do the letters C, E, F and H weigh?",
    nl: "Hoeveel wegen de gewichten:  C, E, F en H?",
    fr: "Combien pèse les poids :  C, E, F et H ?",
    de: "Wie viel kosten die Gewichte:  C, E, F und H?",
  },
  "The text mentions two locations. Go outside and look for these locations.": {
    en: "The text mentions two locations. Go outside and look for these locations.",
    nl: "In de tekst worden twee locaties genoemd. Ga buiten op zoek naar deze plekken.",
    fr: "Le texte mentionne deux endroits. Cherchez ces endroits.",
    de: "Im Text werden zwei Orte genannt. Gehen Sie nach draußen und suchen Sie diese Orte.",
  },
  "Look for signs in these locations? Do you see the signs on the box?": {
    en: "Look for signs in these locations? Do you see the signs on the box?",
    nl: "Zoek naar tekens op deze plekken? Zie je de tekens ook op de kist?",
    fr: "Cherchez des signes dans ces endroits ? Vous voyez les signes sur la boîte?",
    de: "Suchen Sie an diesen Orten nach Zeichen? Sehen Sie die Zeichen auf der Schachtel?",
  },
  "Slide the sliding compartment open. First put the magnet on the first sign. Then put the magnet on the second sign while you slide the box open.":
    {
      en: "Slide the sliding compartment open. First put the magnet on the first sign. Then put the magnet on the second sign while you slide the box open.",
      nl: "Schuif het schuifvak open. Zet daarvoor eerst de magneet op het eerste teken. Vervolgens op het tweede teken terwijl je het vak openschuift.",
      fr: "Faites glisser le compartiment coulissant pour l'ouvrir. Mettez d'abord l'aimant sur le premier panneau. Placez ensuite l'aimant sur le deuxième panneau pendant que vous faites glisser la boîte pour l'ouvrir.",
      de: "Schieben Sie das Schiebefach auf. Bringen Sie den Magneten zunächst am ersten Schild an. Dann halten Sie den Magneten an das zweite Schild, während Sie die Schachtel aufschieben.",
    },
  "Slide the magnet over the back of the box, following the instructions.": {
    en: "Slide the magnet over the back of the box, following the instructions.",
    nl: "Schuif de magneet over de achterzijde van de kist, volg de aanwijzingen die je daarvoor krijgt.",
    fr: "Faites glisser l'aimant sur l'arrière de la boîte, en suivant les instructions.",
    de: "Schieben Sie den Magneten über die Rückseite des Kartons und folgen Sie dabei den Anweisungen.",
  },
  "When you reach the end of the journey, open the back of the box.": {
    en: "When you reach the end of the journey, open the back of the box.",
    nl: "Open wanneer je aan het einde bent van de reis de achterkant van de kist. ",
    fr: "Lorsque vous arrivez à la fin du voyage, ouvrez l'arrière du coffre.",
    de: "Wenn du das Ende des Weges erreicht hast, öffne die Rückseite der Truhe.",
  },
  "The order of the signs is:": {
    en: "The order of the signs is:",
    nl: "De volgorde van de tekens is:",
    fr: "L'ordre des signes est le suivant:",
    de: "Die Reihenfolge der Zeichen ist:",
  },
  "Start from the drawn position.": {
    en: "Start from the drawn position.",
    nl: "Start vanuit de getekende positie.",
    fr: "Commencez par la position dessine.",
    de: "Beginnen Sie in der gezogenen Position.",
  },
  "The number of steps to be taken to turn the disc is indicated by the hands of the clocks.":
    {
      en: "The number of steps to be taken to turn the disc is indicated by the hands of the clocks.",
      nl: "Het aantal stappen dat de schijf gedraaid moet worden, wordt aangegeven door de wijzers van de klokken.",
      fr: "Le nombre de pas pour tourner le disque est indiqué par les aiguilles des horloges.",
      de: "Die Anzahl der Schritte, die zum Drehen der Scheibe erforderlich sind, wird durch die Zeiger der Uhren angezeigt.",
    },
  "After turning the three discs, you get the following code.": {
    en: "After turning the three discs, you get the following code.",
    nl: "Na het draaien van de drie schijven, krijg je de volgende code.",
    fr: "Après avoir tourné les trois disques, vous obtenez le code suivant.",
    de: "Nachdem Sie die drei Scheiben gedreht haben, erhalten Sie den folgenden Code.",
  },
  Continue: {
    en: "Continue",
    nl: "Verder",
    fr: "Continuer",
    de: "Fortsetzen",
  },
  "The code of the next box is:": {
    en: "The code of the next box is:",
    nl: "De code van het volgende vak is:",
    fr: "Le code de la boîte suivante est:",
    de: "Der Code des nächsten Feldes lautet:",
  },
  "Cleanup Instructions": {
    en: "Cleanup Instructions",
    nl: "Opruim instructies",
    fr: "Instructions de nettoyage",
    de: "Anweisungen zum Aufräumen",
  },
  "Snap a picture": {
    en: "Snap a picture",
    nl: "Maak een foto",
    fr: "Prenez une photo",
    de: "Ein Foto machen",
  },
  Done: {
    en: "Done",
    nl: "Klaar",
    fr: "Fini",
    de: "Erledigt",
  },
  "Unfortunately, time is up. And you didn't manage to open the box this time. But see it as something good, you were together. Really together!":
    {
      en: "Unfortunately, time is up. And you didn't manage to open the box this time. But see it as something good, you were together. Really together!",
      nl: "Helaas, de tijd is om. En het is jullie dit keer niet gelukt om de box te openen. Maar maak zie het als iets goed, jullie waren samen. Echt samen!",
      fr: "Malheureusement, le temps est écoulé. Et tu n'as pas réussi à ouvrir la boîte cette fois. Mais maquillez ça en quelque chose de bien, vous étiez ensemble. Vraiment ensemble !",
      de: "Leider ist die Zeit abgelaufen. Und dieses Mal ist es Ihnen nicht gelungen, die Schachtel zu öffnen. Aber stellen Sie es als etwas Gutes dar, dass Sie zusammen waren. Wirklich zusammen!",
    },
  "Only the gamemaster can control the game.": {
    en: "Only the gamemaster can control the game.",
    nl: "Alleen de gamemaster kan het spel besturen.",
    fr: "Alleen de gamemaster kan het spel besturen.",
    de: "Nur der gamemaster kann das Spiel kontrollieren.",
  },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%201%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=60d6fd3e-43cf-485a-b6b0-94edd8d87095":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%201%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=60d6fd3e-43cf-485a-b6b0-94edd8d87095",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_1%20(720p).mp4?alt=media&token=9ea24081-0eae-4ea0-b59f-2fdff929d932",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%201%20-%20ondertiteld%20-%20Duits.mp4?alt=media&token=5e65cb33-114e-4c71-a04e-f7481e86663b",
    },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%202%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=e85c761a-5a26-459c-868f-b2f2483ff321":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%202%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=e85c761a-5a26-459c-868f-b2f2483ff321",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_2%20(720p).mp4?alt=media&token=0133d5bb-f0dd-4d9c-96ad-8c884b91166f",
      fr: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Frans%2FSpel%202%20-%20ondertiteld%20-%20Frans.mp4?alt=media&token=836483c3-70b6-4a0b-808b-6af30f5be22c",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%202%20-%20ondertiteld%20-%20Duits.mp4?alt=media&token=da876df5-3813-4516-ab5d-4ed37612b7bf",
    },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%203%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=4d844e63-dc7c-49d1-b618-5c8c9b4d0351":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%203%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=4d844e63-dc7c-49d1-b618-5c8c9b4d0351",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_3%20(720p).mp4?alt=media&token=09a637b4-070e-49eb-ba4d-e7aed996ab57",
      fr: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Frans%2FSpel%203%20-%20ondertiteld%20-%20Frans.mp4?alt=media&token=519b70df-fd76-4278-9881-44b82d838c86",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%203%20-%20ondertiteld%20-%20Duits.mp4?alt=media&token=e29c382c-a2dd-49f7-bc73-cc399d7bbbfc",
    },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%204%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=80af3635-9e29-4f98-940d-4f1f23145df4":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%204%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=80af3635-9e29-4f98-940d-4f1f23145df4",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_4%20(720p).mp4?alt=media&token=d0a101ce-5b9d-4870-b9c2-aae6cdf9acdf",
      fr: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Frans%2FSpel%204%20-%20ondertiteld%20-%20Frans.mp4?alt=media&token=717c19b8-ba8a-441a-a272-78e3c1fcfa5b",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%204%20-%20ondertiteling%20-%20Duits.mp4?alt=media&token=5070dc5b-de07-445c-b992-3c2d567fcb1c",
    },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%205%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=f88703aa-d7e2-4a3c-8a01-2a0bf6a9345b":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%205%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=f88703aa-d7e2-4a3c-8a01-2a0bf6a9345b",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_5%20(720p).mp4?alt=media&token=a283891c-a58d-4b95-95cb-88872ad6ff9d",
      fr: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Frans%2FSpel%205%20-%20ondertiteld%20-%20Frans.mp4?alt=media&token=96e7ac02-9a36-4dfb-ba82-4cb0cb3966f7",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%205%20-%20ondertiteld%20-%20Duits.mp4?alt=media&token=962304ef-1c60-4646-89d9-edb269ebf719",
    },
  "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%206%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=3788d22c-02d5-41ae-8b53-455dd4c324c5":
    {
      en: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Engels%2FSpel%206%20-%20ondertiteld%20-%20Engels.mp4?alt=media&token=3788d22c-02d5-41ae-8b53-455dd4c324c5",
      nl: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Nederlands%20klein%2Fspel_6%20(720p).mp4?alt=media&token=f59585d7-e76d-4a8a-9585-e4b3aa974d71",
      fr: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Frans%2FSpel%206%20-%20ondertiteld%20-%20Frans.mp4?alt=media&token=f014b935-7687-4bf8-996b-a75d8642b510",
      de: "https://firebasestorage.googleapis.com/v0/b/de-kist.appspot.com/o/Filmpjes%20Duits%2FSpel%206%20-%20ondertiteld%20-%20Duits.mp4?alt=media&token=aa3ce3de-e2f5-48d9-87ff-74b968992160",
    },
  "Scan the correct QR code to continue": {
    en: "Scan the correct QR code to continue",
    nl: "Scan de juiste QR code om verder te gaan",
    fr: "Scannez le bon code QR pour continuer",
    de: "Scannen Sie den richtigen QR-Code, um fortzufahren",
  },
  Step: {
    en: "Step",
    nl: "Stap",
    fr: "Étape",
    de: "Schritt",
  },
  "You finished in": {
    en: "You finished in",
    nl: "Je bent geeïndigd in",
    fr: "Vous avez fini dans",
    de: "Sie landeten in",
  },
  and: {
    en: "and",
    nl: "en",
    fr: "et",
    de: "und",
  },
  "without using any hints!": {
    en: "without using any hints!",
    nl: "zonder hints te gebruiken!",
    fr: "sans utiliser d'indices !",
    de: "ohne irgendwelche Hinweise zu benutzen!",
  },
  with: {
    en: "with",
    nl: "met",
    fr: "avec",
    de: "mit",
  },
  "penalty for using hints!": {
    en: "penalty for using hints!",
    nl: "straf voor het gebruik van hints!",
    fr: "pénalité pour l'utilisation d'indices !",
    de: "Strafe für die Verwendung von Hinweisen!",
  },
};

export let useTranslation = with_translation(translations);

import React from "react";
import dialogPolyfill from "dialog-polyfill";
import "dialog-polyfill/dist/dialog-polyfill.css";
import { useDialogRef } from "./use";

export let Dialog = React.forwardRef(
  (
    /** @type {import("react").HTMLProps<HTMLDialogElement> & { onClose?: (e: CloseEvent) => void }} */ props,
    ref
  ) => {
    /** @type {import("react").MutableRefObject<HTMLDialogElement>} */
    let dialog_ref = /** @type {any} */ (ref);

    React.useLayoutEffect(() => {
      // @ts-ignore
      dialogPolyfill.registerDialog(dialog_ref.current);

      /** @type {NodeListOf<HTMLVideoElement>} */
      let playable_elements = dialog_ref.current.querySelectorAll(
        "video[autoplay], audio[autoplay]"
      );
      for (let element of playable_elements) {
        element.pause();
        element.currentTime = 0;
      }
    }, []);

    return (
      <dialog
        {...props}
        ref={dialog_ref}
        // @ts-ignore
        onClose={(e) => {
          let playable_elements =
            e.currentTarget.querySelectorAll("video, audio");
          for (let playable of playable_elements) {
            playable.pause();
          }
          props?.onClose?.(e);
        }}
      />
    );
  }
);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import React from "react";

import { getDatabase, ref, onValue, set, get } from "firebase/database";
import { withSuspense } from "./with-suspense.js";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXgoaUFxxQ_UZ6K1aTmxCAspsQBSSD2FQ",
  authDomain: "exp-euro-parks-game.firebaseapp.com",
  databaseURL:
    "https://exp-euro-parks-game-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "exp-euro-parks-game",
  storageBucket: "exp-euro-parks-game.appspot.com",
  messagingSenderId: "674148220727",
  appId: "1:674148220727:web:a189c7b4cba94df340a4b2",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const database = getDatabase();

/** @param {import("firebase/database").Query} query */
export let reference_or_query_to_string = (query) => {
  // @ts-ignore
  let query_identifier = query?._queryIdentifier ?? "";
  let result = query.toString() + "?q=" + query_identifier;
  return result;
};

let useFirebaseFirstValue = withSuspense(
  (/** @type {import("firebase/database").Query} */ path) => {
    return reference_or_query_to_string(path);
  },
  (/** @type {import("firebase/database").Query} */ path) => {
    // return get(path).then((x) => x.val());
    return new Promise((yell) => {
      let unsub = () => {};
      unsub = onValue(path, (snapshot) => {
        unsub();
        yell(snapshot.val());
      });
    });
  }
);

export let useFirebase = (
  /** @type {import("firebase/database").Query} */ path
) => {
  let value = useFirebaseFirstValue(path);

  // let [value, set_value] = React.useState(initial_value);
  React.useEffect(() => {
    let dispose = onValue(path, (snapshot) => {
      useFirebaseFirstValue.refetch(path, snapshot.val());
      // set_value(snapshot.val());
    });

    return () => dispose();
  }, [reference_or_query_to_string(path)]);

  return value;
};
